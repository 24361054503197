import { apiClient } from '../index';

/**
 * [Crea plan de cuota de mantenimiento]
 * @return {[type]}          [description]
 */
 function create({ concept, description, paymentYear, paymentDueDay, amount }) {
  return apiClient.post('/v1/maintenance-payments/fees', { concept, description, paymentYear, paymentDueDay, amount });
}

/**
 * [Elimina un plan de cuota de mantenimiento]
 * @return {[type]}          [description]
 */
function eliminateMaintFee( planId ) {
  return apiClient.post('/v1/maintenance-payments/fees/delete', { planId });
}


/**
 * [CEncuentra las cutotas de mantenimiento según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
 function fetch(paymentYear, limit = 30) {
  return apiClient.get('/v1/maintenance-payments/fees', {
    params: {
      paymentYear, limit
    }
  });
}

/**
 * [CEncuentra las cutotas de mantenimiento según los criterios customerId y 
 *  maintenanceFeeId ]
 * @return {[type]}          [description]
 */
function fetchUserPlan( customerId, maintenanceFeeId ) {
  return apiClient.get('/v1/maintenance-payments/fees/userplan', {
    params: {
      customerId, maintenanceFeeId
    }
  });
}


/**
 * [Subscribe un usuario a una cuota de mantenimiento]
 * @return {[type]}          [description]
 */
 function suscribe(maintenanceFeeId, userId) {
  return apiClient.post(`/v1/maintenance-payments/fees/${maintenanceFeeId}/subscribe/user/${userId}`);
}

/**
 * [Subscribe varios usuarios a una cuota de mantenimiento]
 * @return {[type]}          [description]
 */
 function suscribeBatch(maintenanceFeeId, userIds, months) {
  return apiClient.post(`/v1/maintenance-payments/fees/${maintenanceFeeId}/subscribe/users`, { userIds, months });
}

/**
 * [Unsuscribe a un usuario de un plan de mantenimiento]
 * @return {[type]}          [description]
 */
function unsuscribe(userId, maintenanceFeeId) {
  return apiClient.post(`/v1/maintenance-payments/fees/unsuscribe`, { userId, maintenanceFeeId });
}

/**
 * [Encuentra todos los usuarios suscritos a un plan de mantenimiento]
 * @return {[type]}          [description]
 */
 function usersSuscribed(maintenanceFeeId, limit = 500) {
  return apiClient.get('/v1/maintenance-payments/suscribed/users', { params: {
    maintenanceFeeId, limit
  } });
}


/**
 * [Encuentra los pagos de manteniemto según los criterios de búsqueda]
 * @return {[type]}          [description]
 */
 function searchPayment(status, customerId, maintenanceFeeId, startDate, endDate, limit = 100) {
  return apiClient.get('/v1/maintenance-payments/search', { params: {
    status, customerId, maintenanceFeeId, limit, startDate, endDate,
  } });
}

/**
 * [Acredita el pago de una cuota de mantenimiento pendiente]
 * @return {[type]}          [description]
 */
 function payByIdPayment(id, payment) {
  return apiClient.patch(`/v1/maintenance-payments/${id}/pay`, payment);
}


/**
 * [Cancela los recargos de un pago de mantenimiento pendiente]
 * @return {[type]}          [description]
 */
 function cancelSurchargesByIdPayment(id) {
  return apiClient.patch(`/v1/maintenance-payments/${id}/surcharges/cancel`);
}


/**
 * [Encuentra todos los deudores de cutotas de mantenimiento]
 * @return {[type]}          [description]
 */
 function fetchDebtors() {
  return apiClient.get('/v1/maintenance-payments/debtors');
}

/**
 * [Devuelve los pagos de un plan de pagos a cuotas mantenimiento]
 * @return {[type]}          [description]
 */
 function fetchByPlan(id) {
  return apiClient.get(`/v1/maintenance-payments/feesbyplanid/${id}`);
}

/**
 * [Devuelve los planes de cuotas mantenimiento a los que esta suscrito un usuario]
 * @return {[type]}          [description]
 */
function fetchUserSuscriptions (userId) {
  return apiClient.get(`/v1/maintenance-payments/userMaintPlans/${userId}`);
}

/**
 * [Devuelve el recibo de pago en formato PDF]
 * @param {string} username - Nombre de usuario
 * @param {number} amount - Monto del pago
 * @param {string} paymentDate - Fecha del pago (YYYY-MM-DD)
 * @param {string} concept - Concepto del pago
 * @return {Promise} - Resuelve con el PDF en formato blob
 */
function receiptPdf(username, lote, amount, paymentDate, concept) {
 
  return apiClient.post(
    `/v1/maintenance-payments/receipt-pdf`, 
    {username, lote, amount, paymentDate, concept}, // Empty body since params are in the query
    {    
      responseType: 'blob' // Ensures response is a PDF file
    }
  );
}



export default {
  create,
  eliminateMaintFee,
  fetch,
  fetchUserPlan,
  suscribe,
  suscribeBatch,
  unsuscribe,
  searchPayment,
  payByIdPayment,
  cancelSurchargesByIdPayment,
  fetchDebtors,
  usersSuscribed,
  fetchByPlan,
  fetchUserSuscriptions,
  receiptPdf,
}