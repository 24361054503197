<template>
  <div>
    <div style="display: flex; align-items: center; justify-content: center; padding: 0px 0px 10px 0px">
      <span class="title-user-scr-b" style="font-size:14px;">Cuenta de Pago transferencia o deposito bancario</span>      
    </div>
    <div>Banco: Santander </div>
    <div>Dzidzil-Ha AC </div>
    <div>Cuenta: 65-50692601-6 </div>
    <div style="padding: 0px 0px 30px 0px">Clave Interbancaria : 014910655069260163</div>
    <v-btn 
      block color="primary" 
      large rounded 
      link :to="{ name: 'maintenancefees' }"
      
      >Cuotas de mantenimiento</v-btn>
    <br />   
    <!-- <v-btn block color="primary" large rounded link :to="{ name: 'eventstopay' }">Renta de amenidades</v-btn>
    <br /> -->

  <!--  <v-btn block color="primary" large rounded outlined link :to="{ name: 'spendinformation' }"
      >¿En qué se gasta mi dinero?</v-btn
    > -->
  </div>
</template>


<script>
export default {};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>